<template>
  <div>
    <v-row id="tabs" v-if="$vuetify.breakpoint.smAndDown">
      <v-col cols="12" align="center">
        <v-btn-toggle v-model="tab" mandatory>
          <v-btn
            height="30"
            value="ingresos"
            active-class="selected"
            outlined
            >{{ $t("ingresos") }}</v-btn
          >
          <v-btn height="30" value="gastos" active-class="selected" outlined>{{
            $t("gastos")
          }}</v-btn>
        </v-btn-toggle>
      </v-col>

      <v-tabs-items v-model="tab">
        <v-tab-item value="ingresos">
          <v-col cols="12" md="6">
            <v-card raised elevation="6" class="mt-0">
              <v-card-title
                class="
              text-decoration-underline text-uppercase text-center
              d-block
              gris3
              pa-2
            "
              >
                {{ $t("appointments.income") }}
              </v-card-title>
              <v-card-text class="card_background">
                <v-row align="center">
                  <v-col cols="12" md="6">
                    <base-border-card
                      classes="my-2"
                      title="CITA <br/> EXISTENTE"
                      @card_clicked="$refs.services.open()"
                    ></base-border-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <base-border-card
                      classes="my-2"
                      title="ENTRADA <br/> EFECTIVO"
                      @card_clicked="
                        $refs.input.dialog = true;
                        $refs.input.expense = {};
                      "
                    ></base-border-card>
                  </v-col>

                  <v-col cols="12" md="6">
                    <base-border-card
                      classes="my-2"
                      @card_clicked="
                        $refs.formMove_salidas.openForm();
                        $refs.formMove_salidas.fetchAll();
                      "
                      title="SALIDA <br/> ITEM"
                    ></base-border-card>
                  </v-col>

                  <v-col cols="12" md="6">
                    <base-border-card
                      classes="my-2"
                      title="OTROS <br/> INGRESOS"
                      @card_clicked="$refs.confirmed_ingresos.open()"
                    ></base-border-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <Form
                action="save"
                :expense="expense"
                type="services"
                ref="services"
              ></Form>

              <CashInput
                ref="input"
                @update="$emit('update')"
                :hidden="true"
              ></CashInput>

              <FormMove
                :title="'salidas'"
                ref="formMove_salidas"
                :hidden="true"
                @update="$emit('update')"
              />

              <Form
                action="save"
                :expense="expense"
                type="confirmed_ingresos"
                ref="confirmed_ingresos"
              ></Form>
            </v-card>
          </v-col>
        </v-tab-item>
        <v-tab-item value="gastos">
          <v-col cols="12" md="6">
            <v-card raised elevation="6" class="mt-0">
              <v-card-title
                class="
              text-decoration-underline text-uppercase text-center
              d-block
              gris3
              pa-2
            "
              >
                Gastos
              </v-card-title>

              <v-card-text class="card_background">
                <v-row align="center">
                  <v-col cols="12" md="6">
                    <base-border-card
                      classes="my-2"
                      title="PAGAR <br/> ARTISTA"
                      @card_clicked="$emit('to_debit')"
                    ></base-border-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <base-border-card
                      classes="my-2"
                      title="SALIDA <br/> EFECTIVO"
                      @card_clicked="
                        $refs.output.dialog = true;
                        $refs.output.expense = {};
                      "
                    ></base-border-card>
                  </v-col>

                  <v-col cols="12" md="6">
                    <base-border-card
                      classes="my-2"
                      title="COMPRA <br/> STOCK"
                      @card_clicked="
                        $refs.formMove_entradas.openForm();
                        $refs.formMove_entradas.fetchAll();
                      "
                    ></base-border-card>
                  </v-col>

                  <v-col cols="12" md="6">
                    <base-border-card
                      classes="my-2"
                      title="OTROS <br/> GASTOS"
                      @card_clicked="$refs.confirmed_gastos.open()"
                    ></base-border-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <FormMove
                :title="'entradas'"
                ref="formMove_entradas"
                :hidden="true"
                @update="$emit('update')"
              />
              <CashOutput
                ref="output"
                @update="$emit('update')"
                :hidden="true"
              ></CashOutput>

              <Form
                action="save"
                :expense="expense"
                type="confirmed_gastos"
                ref="confirmed_gastos"
              ></Form>
            </v-card>
          </v-col>
        </v-tab-item>
      </v-tabs-items>
    </v-row>
    <v-row class="full" align-center v-else>
      <v-col cols="12" md="6">
        <v-card raised elevation="6">
          <v-card-title
            class="
              text-decoration-underline text-uppercase text-center
              d-block
              gris3
              pa-2
            "
          >
            {{ $t("appointments.income") }}
          </v-card-title>
          <v-card-text class="card_background">
            <v-row align="center">
              <v-col cols="12" md="6">
                <base-border-card
                  classes="my-2"
                  title="CITA <br/> EXISTENTE"
                  @card_clicked="$refs.services.open()"
                ></base-border-card>
              </v-col>
              <v-col cols="12" md="6">
                <base-border-card
                  classes="my-2"
                  title="ENTRADA <br/> EFECTIVO"
                  @card_clicked="
                    $refs.input.dialog = true;
                    $refs.input.expense = {};
                  "
                ></base-border-card>
              </v-col>

              <v-col cols="12" md="6">
                <base-border-card
                  classes="my-2"
                  @card_clicked="
                    $refs.formMove_salidas.openForm();
                    $refs.formMove_salidas.fetchAll();
                  "
                  title="SALIDA <br/> ITEM"
                ></base-border-card>
              </v-col>

              <v-col cols="12" md="6">
                <base-border-card
                  classes="my-2"
                  title="OTROS <br/> INGRESOS"
                  @card_clicked="$refs.confirmed_ingresos.open()"
                ></base-border-card>
              </v-col>
            </v-row>
          </v-card-text>
          <Form
            action="save"
            :expense="expense"
            type="services"
            ref="services"
          ></Form>

          <CashInput
            ref="input"
            @update="$emit('update')"
            :hidden="true"
          ></CashInput>

          <FormMove
            :title="'salidas'"
            ref="formMove_salidas"
            :hidden="true"
            @update="$emit('update')"
          />

          <Form
            action="save"
            :expense="expense"
            type="confirmed_ingresos"
            ref="confirmed_ingresos"
          ></Form>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title
            class="
              text-decoration-underline text-uppercase text-center
              d-block
              gris3
              pa-2
            "
          >
            Gastos
          </v-card-title>

          <v-card-text class="card_background">
            <v-row align="center">
              <v-col cols="12" md="6">
                <base-border-card
                  classes="my-2"
                  title="PAGAR <br/> ARTISTA"
                  @card_clicked="$emit('to_debit')"
                ></base-border-card>
              </v-col>
              <v-col cols="12" md="6">
                <base-border-card
                  classes="my-2"
                  title="SALIDA <br/> EFECTIVO"
                  @card_clicked="
                    $refs.output.dialog = true;
                    $refs.output.expense = {};
                  "
                ></base-border-card>
              </v-col>

              <v-col cols="12" md="6">
                <base-border-card
                  classes="my-2"
                  title="COMPRA <br/> STOCK"
                  @card_clicked="
                    $refs.formMove_entradas.openForm();
                    $refs.formMove_entradas.fetchAll();
                  "
                ></base-border-card>
              </v-col>

              <v-col cols="12" md="6">
                <base-border-card
                  classes="my-2"
                  title="OTROS <br/> GASTOS"
                  @card_clicked="$refs.confirmed_gastos.open()"
                ></base-border-card>
              </v-col>
            </v-row>
          </v-card-text>
          <FormMove
            :title="'entradas'"
            ref="formMove_entradas"
            :hidden="true"
            @update="$emit('update')"
          />
          <CashOutput
            ref="output"
            @update="$emit('update')"
            :hidden="true"
          ></CashOutput>

          <Form
            action="save"
            :expense="expense"
            type="confirmed_gastos"
            ref="confirmed_gastos"
          ></Form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "SimpleOperations",
  data() {
    return {
      expense: {},
      tab: "ingresos",
    };
  },
  computed: {
    tabs() {
      return [
        { href: "ingresos", text: this.$t("ingresos") },
        { href: "gastos", text: this.$t("gastos") },
      ];
    },
  },
  components: {
    Form: () => import("@/components/expense/Form"),
    FormMove: () => import("@/components/inventorymovement/FormMove"),
    CashOutput: () => import("@/components/expense/CashOutput"),
    CashInput: () => import("@/components/expense/CashInput"),
  },
};
</script>
<style lang="sass" scoped>
.row.full
  min-height: calc( 100vh - 180px)
  .v-card
    height: 90%
    .v-card__text
      height: calc( 100% - 40px)
      .row
        height: 100%

#tabs
  .v-btn-toggle .v-btn
    background-color: var(--v-gris2-base)
    .selected
      color: black !important
      border-color: var(--v-primary-base) !important
      background-color: var(--v-primary-base) !important
</style>
