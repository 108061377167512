var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{attrs:{"id":"tabs"}},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-btn',{attrs:{"height":"30","value":"ingresos","active-class":"selected","outlined":""}},[_vm._v(_vm._s(_vm.$t("ingresos")))]),_c('v-btn',{attrs:{"height":"30","value":"gastos","active-class":"selected","outlined":""}},[_vm._v(_vm._s(_vm.$t("gastos")))])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"ingresos"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mt-0",attrs:{"raised":"","elevation":"6"}},[_c('v-card-title',{staticClass:"\n            text-decoration-underline text-uppercase text-center\n            d-block\n            gris3\n            pa-2\n          "},[_vm._v(" "+_vm._s(_vm.$t("appointments.income"))+" ")]),_c('v-card-text',{staticClass:"card_background"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"CITA <br/> EXISTENTE"},on:{"card_clicked":function($event){return _vm.$refs.services.open()}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"ENTRADA <br/> EFECTIVO"},on:{"card_clicked":function($event){_vm.$refs.input.dialog = true;
                      _vm.$refs.input.expense = {};}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"SALIDA <br/> ITEM"},on:{"card_clicked":function($event){_vm.$refs.formMove_salidas.openForm();
                      _vm.$refs.formMove_salidas.fetchAll();}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"OTROS <br/> INGRESOS"},on:{"card_clicked":function($event){return _vm.$refs.confirmed_ingresos.open()}}})],1)],1)],1),_c('Form',{ref:"services",attrs:{"action":"save","expense":_vm.expense,"type":"services"}}),_c('CashInput',{ref:"input",attrs:{"hidden":true},on:{"update":function($event){return _vm.$emit('update')}}}),_c('FormMove',{ref:"formMove_salidas",attrs:{"title":'salidas',"hidden":true},on:{"update":function($event){return _vm.$emit('update')}}}),_c('Form',{ref:"confirmed_ingresos",attrs:{"action":"save","expense":_vm.expense,"type":"confirmed_ingresos"}})],1)],1)],1),_c('v-tab-item',{attrs:{"value":"gastos"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mt-0",attrs:{"raised":"","elevation":"6"}},[_c('v-card-title',{staticClass:"\n            text-decoration-underline text-uppercase text-center\n            d-block\n            gris3\n            pa-2\n          "},[_vm._v(" Gastos ")]),_c('v-card-text',{staticClass:"card_background"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"PAGAR <br/> ARTISTA"},on:{"card_clicked":function($event){return _vm.$emit('to_debit')}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"SALIDA <br/> EFECTIVO"},on:{"card_clicked":function($event){_vm.$refs.output.dialog = true;
                      _vm.$refs.output.expense = {};}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"COMPRA <br/> STOCK"},on:{"card_clicked":function($event){_vm.$refs.formMove_entradas.openForm();
                      _vm.$refs.formMove_entradas.fetchAll();}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"OTROS <br/> GASTOS"},on:{"card_clicked":function($event){return _vm.$refs.confirmed_gastos.open()}}})],1)],1)],1),_c('FormMove',{ref:"formMove_entradas",attrs:{"title":'entradas',"hidden":true},on:{"update":function($event){return _vm.$emit('update')}}}),_c('CashOutput',{ref:"output",attrs:{"hidden":true},on:{"update":function($event){return _vm.$emit('update')}}}),_c('Form',{ref:"confirmed_gastos",attrs:{"action":"save","expense":_vm.expense,"type":"confirmed_gastos"}})],1)],1)],1)],1)],1):_c('v-row',{staticClass:"full",attrs:{"align-center":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"raised":"","elevation":"6"}},[_c('v-card-title',{staticClass:"\n            text-decoration-underline text-uppercase text-center\n            d-block\n            gris3\n            pa-2\n          "},[_vm._v(" "+_vm._s(_vm.$t("appointments.income"))+" ")]),_c('v-card-text',{staticClass:"card_background"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"CITA <br/> EXISTENTE"},on:{"card_clicked":function($event){return _vm.$refs.services.open()}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"ENTRADA <br/> EFECTIVO"},on:{"card_clicked":function($event){_vm.$refs.input.dialog = true;
                  _vm.$refs.input.expense = {};}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"SALIDA <br/> ITEM"},on:{"card_clicked":function($event){_vm.$refs.formMove_salidas.openForm();
                  _vm.$refs.formMove_salidas.fetchAll();}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"OTROS <br/> INGRESOS"},on:{"card_clicked":function($event){return _vm.$refs.confirmed_ingresos.open()}}})],1)],1)],1),_c('Form',{ref:"services",attrs:{"action":"save","expense":_vm.expense,"type":"services"}}),_c('CashInput',{ref:"input",attrs:{"hidden":true},on:{"update":function($event){return _vm.$emit('update')}}}),_c('FormMove',{ref:"formMove_salidas",attrs:{"title":'salidas',"hidden":true},on:{"update":function($event){return _vm.$emit('update')}}}),_c('Form',{ref:"confirmed_ingresos",attrs:{"action":"save","expense":_vm.expense,"type":"confirmed_ingresos"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',{staticClass:"\n            text-decoration-underline text-uppercase text-center\n            d-block\n            gris3\n            pa-2\n          "},[_vm._v(" Gastos ")]),_c('v-card-text',{staticClass:"card_background"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"PAGAR <br/> ARTISTA"},on:{"card_clicked":function($event){return _vm.$emit('to_debit')}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"SALIDA <br/> EFECTIVO"},on:{"card_clicked":function($event){_vm.$refs.output.dialog = true;
                  _vm.$refs.output.expense = {};}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"COMPRA <br/> STOCK"},on:{"card_clicked":function($event){_vm.$refs.formMove_entradas.openForm();
                  _vm.$refs.formMove_entradas.fetchAll();}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-border-card',{attrs:{"classes":"my-2","title":"OTROS <br/> GASTOS"},on:{"card_clicked":function($event){return _vm.$refs.confirmed_gastos.open()}}})],1)],1)],1),_c('FormMove',{ref:"formMove_entradas",attrs:{"title":'entradas',"hidden":true},on:{"update":function($event){return _vm.$emit('update')}}}),_c('CashOutput',{ref:"output",attrs:{"hidden":true},on:{"update":function($event){return _vm.$emit('update')}}}),_c('Form',{ref:"confirmed_gastos",attrs:{"action":"save","expense":_vm.expense,"type":"confirmed_gastos"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }